<script setup lang="ts">
type _VTI_TYPE_DebitorProfileType = "debitor" | "creditor"
type _VTI_TYPE_ReviewStateEnum = "created" | "edited" | "clean"
interface _VTI_TYPE_AccountContact {
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: default, list, Address
     */
    addressLine?: string
    /**
     * Groups: default, list, Address
     */
    addressLine2?: string
    /**
     * Groups: default, list, AddressTrait.street, Address
     */
    street?: string
    /**
     * Groups: default, list, AddressTrait.streetNumber, Address
     */
    streetNumber?: string
    /**
     * Groups: default, list, AddressTrait.zip, Address
     */
    zip?: string
    /**
     * Groups: default, list, AddressTrait.city, Address
     */
    city?: string
    /**
     * Groups: default, list, AddressTrait.country, Address
     */
    country?: string
    /**
     * Groups: default, list
     */
    type?: string
    /**
     * Groups: default, list
     */
    primary?: boolean
    /**
     * Groups: default, list
     */
    locked?: boolean
    /**
     * Groups: default, list
     */
    notice?: string
    /**
     * Groups: default, list
     */
    value?: string
    /**
     * Groups: default, list
     */
    iban?: string
    /**
     * Groups: default, list
     */
    bic?: string
    /**
     * Groups: default, list
     */
    accountOwner?: string
    /**
     * Groups: default, list
     */
    bankName?: string
    /**
     * Groups: default
     */
    isAssignedToAthlete?: boolean
}
interface _VTI_TYPE_DebitorProfile {
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: default, list, Address
     */
    addressLine?: string
    /**
     * Groups: default, list, Address
     */
    addressLine2?: string
    /**
     * Groups: default, list, AddressTrait.street, Address
     */
    street?: string
    /**
     * Groups: default, list, AddressTrait.streetNumber, Address
     */
    streetNumber?: string
    /**
     * Groups: default, list, AddressTrait.zip, Address
     */
    zip?: string
    /**
     * Groups: default, list, AddressTrait.city, Address
     */
    city?: string
    /**
     * Groups: default, list, AddressTrait.country, Address
     */
    country?: string
    /**
     * Groups: DebitorProfile, list, DebitorProfile.accountNumber
     */
    accountNumber?: string
    /**
     * Groups: DebitorProfile, list
     */
    name?: string
    /**
     * Groups: DebitorProfile
     */
    matchCode?: string
    /**
     * Groups: DebitorProfile
     */
    vatId?: string
    /**
     * Groups: DebitorProfile, list
     */
    state?: _VTI_TYPE_ReviewStateEnum
    /**
     * Groups: DebitorProfile, list
     */
    type?: _VTI_TYPE_DebitorProfileType
    /**
     * Groups: DebitorProfile, list
     */
    isPrimary?: boolean
}
interface _VTI_TYPE_Account {
    /**
     * Groups: Default, default, list
     */
    disabled?: boolean
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: default, appConfig, select, list, myAthletes, dashboard-invitation, selection-invitation, Account.name
     */
    name?: string
    /**
     * Groups: Account.deletedAt
     */
    deletedAt?: any
    /**
     * Groups: passportlist
     */
    parent?: Account
    /**
     * Groups: default, Account.createdAt
     */
    createdAt?: string /* DateTime */
    /**
     * Groups: Account.debitorProfiles
     */
    debitorProfiles?: any[] | any
    /**
     * Groups: Account.primaryDebitorProfile
     */
    primaryDebitorProfile?: _VTI_TYPE_DebitorProfile
    /**
     * Groups: Default, appConfig, list
     */
    isAdmin?: boolean
    /**
     * Groups: Default, appConfig, list
     */
    isUser?: boolean
    /**
     * Groups: Default, default, appConfig, list
     */
    type?: string
    /**
     * Groups: Account.primaryRegistrationAddress
     */
    primaryRegistrationAddress?: _VTI_TYPE_AccountContact
    /**
     * Groups: admin-list
     */
    listContact?: _VTI_TYPE_AccountContact
    /**
     * Groups: admin-list
     */
    addresses?: any[] | any
    /**
     * Groups: Account.primaryAccountNumber
     */
    primaryAccountNumber?: string
}
type _VTI_TYPE_IssueType = "OrganizationCreation" | "EventRegistration" | "Order" | "ComThread" | "AuditAthleteUpload" | "AccountInvitation" | "OrganizationDebitorProfile" | "StaffInvitation" | "AddressUpdate"
type _VTI_TYPE_IssueState = "open" | "done"
interface _VTI_TYPE_Issue {
    /**
     * Groups: Default, default, list, appConfig, select, id
     */
    id?: string
    /**
     * Groups: default, list
     */
    name?: string
    /**
     * Groups: default, list
     */
    nameProps?: any[] | any
    /**
     * Groups: default, list
     */
    state?: _VTI_TYPE_IssueState
    /**
     * Groups: default, list
     */
    type?: _VTI_TYPE_IssueType
    /**
     * Groups: default, list
     */
    createdAt?: string /* DateTime */
    /**
     * Groups: Issue.list, Issue.owner
     */
    owner?: _VTI_TYPE_Account
    /**
     * Groups: default, list
     */
    lastTaskAt?: string /* DateTime */
    /**
     * Groups: default, list
     */
    taskCount?: number
    /**
     * Groups: default, list
     */
    taskWithoutMessagesCount?: number
    /**
     * Groups: list
     */
    openTaskCount?: number
    /**
     * Groups: list
     */
    openTaskWithoutMessagesCount?: number
    /**
     * Groups: list
     */
    messageCount?: number
    /**
     * Groups: tasks
     */
    tasks?: Task[]
    /**
     * Groups: Issue.issueGroupTree
     */
    issueGroupTree?: IssueGroup[]
    /**
     * Groups: Issue.productGroupTree
     */
    productGroupTree?: ProductGroup[]
    /**
     * Groups: default, list
     */
    closedAt?: string /* DateTime */
}
interface TasksProps {
    task: _VTI_TYPE_T
    issue: _VTI_TYPE_Issue
    viewMode: "customer" | "admin"
    index: number
}
import VarsityInlineConfirm from '@/components/VarsityInlineConfirm.vue'
import VarsityInlineConfirmButton from '@/components/VarsityInlineConfirmButton.vue'
import VarsityInlineConfirmGroup from '@/components/VarsityInlineConfirmGroup.vue'
import { createInlineConfirmCollection } from '@/composables/createInlineConfirm'
import { ApproveAddressUpdateTask } from '@/model/app/approve-address-update-task'
import { useHttpClient } from '@/vf'
const props = defineProps<TasksProps<ApproveAddressUpdateTask>>()
const emit = defineEmits<{
    (e: "updated"): void
}>()
const http = useHttpClient()
const getInlineConfirmController = createInlineConfirmCollection((id, args) => ({
    async action() {
        await http.post(`/account/${props.issue.owner.id}/contact/task/${props.task.id}/approve-update`, {
            approved: id === "approve",
        })
    },
    afterAction() {
        emit("updated")
    },
}))
</script>

<template>
    <div class="p-4">
        <div class="row">
            <div class="col-md-4">
                <div>
                    <b>{{ $t("@tasks:tasks.approve_address_update.original") }}</b>
                </div>
                <div>{{ props.task.originalValues.addressLine }}</div>
                <div>{{ props.task.originalValues.addressLine2 }}</div>
                <div>{{ props.task.originalValues.street }} {{ props.task.originalValues.streetNumber }}</div>
                <div>{{ props.task.originalValues.zip }} {{ props.task.originalValues.city }}</div>
                <div>{{ props.task.originalValues.country }}</div>
            </div>
            <div class="col-md-4">
                <div>
                    <b>{{ $t("@tasks:tasks.approve_address_update.new") }}</b>
                </div>
                <div>{{ props.task.newValues.addressLine }}</div>
                <div>{{ props.task.newValues.addressLine2 }}</div>
                <div>{{ props.task.newValues.street }} {{ props.task.newValues.streetNumber }}</div>
                <div>{{ props.task.newValues.zip }} {{ props.task.newValues.city }}</div>
                <div>{{ props.task.newValues.country }}</div>
            </div>
            <div class="col-md-4">
                <div v-if="props.viewMode === 'admin' && props.task.state === 'open'">
                    <VarsityInlineConfirmGroup>
                        <VarsityInlineConfirmButton :controller="getInlineConfirmController('approve')">
                            {{ $t("@tasks:tasks.approve_address_update.approve.button") }}
                        </VarsityInlineConfirmButton>
                        <VarsityInlineConfirmButton
                            :controller="getInlineConfirmController('decline')"
                            class="btn btn-danger btn-shape-skewed"
                        >
                            {{ $t("@tasks:tasks.approve_address_update.decline.button") }}
                        </VarsityInlineConfirmButton>
                        <VarsityInlineConfirm :controller="getInlineConfirmController('approve')">
                            <template #confirmation>
                                {{ $t("@tasks:tasks.approve_address_update.approve.confirmation") }}
                            </template>
                            <template #success>
                                {{ $t("@tasks:tasks.approve_address_update.approve.success") }}
                            </template>
                        </VarsityInlineConfirm>
                        <VarsityInlineConfirm :controller="getInlineConfirmController('decline')">
                            <template #confirmation>
                                {{ $t("@tasks:tasks.approve_address_update.decline.confirmation") }}
                            </template>
                            <template #success>
                                {{ $t("@tasks:tasks.approve_address_update.decline.success") }}
                            </template>
                        </VarsityInlineConfirm>
                    </VarsityInlineConfirmGroup>
                </div>
            </div>
        </div>
    </div>
</template>
