import type { DebitorProfile } from "@/model/app/debitor-profile"
import type { Organization } from "@/model/app/organization"
import { useHttpClient } from "@/vf"
import { useRouteQuery } from "@vueuse/router"
import { defineStore } from "pinia"
import { computed, ref, watch, type Ref } from "vue"

export interface SelectionTableOrganization {
    id: string
    name: string
    organizationLevel: "main" | "sub"
    parentName: string
    /** Parent Organization ID */
    parent: string
    approved: boolean
    disabled: boolean
    status: "disabled" | "active" | "review"
    accessible: boolean
    hasManagerAccess: boolean
    hasChildren: boolean
    debitorProfiles: {
        name: string
        accountNumber: string
        addressLine: string
        addressLine2: string
        street: string
        streetNumber: string
        zip: string
        city: string
    }[]

    children?: Organization[]
}

export interface FullOrganization {
    id: string
    name: string
    organizationLevel: "main" | "sub" | "alias"
    hasManageOrganizationRole: boolean
    primaryRegistrationAddress: OrganizationAddress
    createdAt: string
    disabled: boolean
    approved: boolean
    requireToAcceptCondition: boolean
    latestConditionId: string
    debitorProfiles?: DebitorProfile[]
    logoName: string
}

export interface OrganizationAddress {
    addressLine: string
    addressLine2: string
    street: string
    streetNumber: string
    zip: string
    city: string
    country: string
}

export const useOrganizationSelection = defineStore("organizationSelection", () => {
    const http = useHttpClient()

    const routeQuery = useRouteQuery("organization", null) as Ref<string | null>

    // selected organization id
    const organizationId = ref(routeQuery.value)

    // currently selected organization
    const organization = ref<FullOrganization | null>()

    // organizations from the api, without children
    const organizations = ref<SelectionTableOrganization[]>([])
    const selectionTableOrganization = ref<SelectionTableOrganization | null>(null)

    // organizations grouped by parents
    const tree: Ref<SelectionTableOrganization[]> = ref([])

    // todo find out what this contains
    const invitations = ref([])

    // the organization id that should be used when no organization is explicitly selected
    const defaultId = ref<string | null>()

    // if set to true, the create sub organization button will be shown
    const createSubOrganizationAvailable = computed(
        () => organization.value?.organizationLevel === "main" && selectionTableOrganization.value?.hasManagerAccess,
    )

    // if set to true, the create sub organization form should be shown
    const createSubOrganizationOpen = ref(false)

    watch(routeQuery, q => {
        if (q === null || q === undefined || q === "" || q === organizationId.value) {
            return
        }

        select(q)
    })

    function updateSelectionTableOrganization() {
        selectionTableOrganization.value = organizations.value.find(o => o.id === organizationId.value) || null
    }

    async function select(id: string | null) {
        if (id && !organizations.value.find(o => o.id === id)) {
            return
        }
        organizationId.value = id
        routeQuery.value = id
        organization.value = id ? await loadFullOrganization(id) : null
        createSubOrganizationOpen.value = false
        updateSelectionTableOrganization()
    }

    async function fetchOrganizations(changeToDefault: boolean = false) {
        const response = (await http.get<any>("/organization/selection")).data

        organizations.value = response.items
        invitations.value = response.invitations
        defaultId.value = response.main ? response.main.id : null
        if (!organizations.value.find(o => o.id === defaultId.value)) {
            organization.value = null
            organizationId.value = null
        }

        if (organizationId.value === null || changeToDefault) {
            await select(defaultId.value)
        } else if (organizationId.value && !organization.value) {
            organization.value = await loadFullOrganization(organizationId.value)
            updateSelectionTableOrganization()
        }

        tree.value = response.items
    }

    async function loadFullOrganization(id: string): Promise<FullOrganization> {
        return (await http.get<FullOrganization>(`/organization/${id}`)).data
    }

    return {
        organization,
        selectionTableOrganization,
        tree,
        invitations,
        organizationId,
        createSubOrganizationAvailable,
        createSubOrganizationOpen,
        hasSelection: computed(() => !!organizationId.value),
        hasSubOrganizations: computed(() => !!tree.value.find(o => o.id === organizationId.value)?.hasChildren),

        select,
        fetchOrganizations,
        loadFullOrganization,
    }
})
